import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SocialSharing } from '@ionic-native/social-sharing';
import * as smoothscroll from 'smoothscroll-polyfill';

import {
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  IonFab,
  IonText,
  IonToast,
  IonButton,
  IonHeader,
  IonButtons,
  IonFooter,
  IonSelect,
  IonCheckbox,
  IonSelectOption,
  IonItem,
  IonLabel
} from '@ionic/react';

import { Mixpanel } from '@ionic-native/mixpanel';

import './ProductView.scss';
import { Page, Modal } from 'components';
import { Button } from 'components/Form';
import {
  IonContent,
  IonToolbar,
} from '@ionic/react';
import Helper from 'Helper';
import { cdnImage } from 'libs/pipes';

import { 
  chevronDownOutline,
  shareSocial,
  star,
  heart,
  heartOutline,
  refreshCircleOutline,
  chevronBackOutline,
  storefrontOutline ,
  removeOutline,
  addOutline,

} from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';
import AppService from 'services/app.service';
import ProductService from 'services/product.service';

import Loading from 'components/Customs/Loading/Loading';

import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router';
import SelectAction from 'store/actions/select';
import CartAction from 'store/actions/cart';

// Core modules imports are same as usual
import { Navigation, Pagination } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import Http from 'libs/http';
import ProductsHelper from '../Products/ProductsHelper';
import { remoteConfig } from 'services/firebase/firebase';
import ProductOptions from 'components/ProductOptions/ProductOptions';
smoothscroll.polyfill();

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.http = new Http;
    
    let searchMode = false;
    
    this.state = {
      showAddToCartToast : false,

      addToCartButtonLoading : false,

      product_id: null,
      categories: [],
      wishlist: [],
      category: {},
      products: [],
      blocks : [],
      subCategory: 0,
      maxCartQuantity: 2,
      orderBy: '1',
      productAddedToCart : false,

      refresher: false,
      productLoaded : false,
      product : null,
      quantity : 1,
      variation : null,
      toast : {
        show : false,
        message : ""
      },
      options : [],
      optionsSelected : [],
      optionsError : null,
      popoverQuantityState : { showPopover: false, event: undefined }
    };

    this.container = null;
    this.categoryRef = null;
    this.refresher = null;
    this.subCategoryRef = null;
    this.subCategoryItemsRef = null;
    this.timeoutRef = null;

    this.service = new AppService();
    this.productService = new ProductService();

    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleMinus = this.handleMinus.bind(this);
    this.handlePlus = this.handlePlus.bind(this);
    
    this.handleFav = this.handleFav.bind(this);
    this.handleSharing = this.handleSharing.bind(this);

  }

  /**
   * On init
   */
  async componentDidMount() {
    await remoteConfig.fetchAndActivate().then(_ => {
      const maxCartQuantity = remoteConfig.getValue("max_cart_quantity")?._value
      this.setState({maxCartQuantity: maxCartQuantity || 2})
    })

    if (this.state.productLoaded == false) {
      // this.getWishlist();
      // this.getProduct();

      const { productData } = this.props;

      // options 
      let newOptions = productData.options.map((option) => {
        return {
          ...option,
          items : option.items.map((item) => {
            return {
              ...item,
              selected : false,
            }
          })
        }
      });
      
      this.setState({
        options : newOptions,
        product : {
          images : [productData.image],
          ...productData
        }
      })

      this.setState({
        productLoaded : true
      })
    }


  }

  /**
   * On unload
   */
  async componentWillUnmount() {
    window.removeEventListener('beforeunload', () => {});
  }

  /**
   * On state update.
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate() {
    const { product, productLoaded } = this.state;

    if (!product && productLoaded != true) {
      this.getProduct();
    }
  }

  handleFav({isFav}) {
    const { auth } = this.props;
    const { product } = this.state;
    
    Mixpanel.track('Add To Wishlist', {
      product_id : Number(product.id),
      product_name : product.name,
      screen : 'Product Page'
    });

    // Check auth
    if (auth['user']['isGuest'] == true) {
      this.setState({ 
        toast : {
          show : true,
          message : 'الرجاء تسجيل الدخول اولا',
          position : 'bottom',
          color : 'danger',
          time : 1200
        }
      });
      return;
    }
    if(isFav){
      this.http.post('wishlist/delete', {
        product_id : product.id
      })
      .then(res => {
        this.getWishlist()
      })
      .catch(err => {
        console.log('err', err);
      });
    }
    else{
      this.http.post('wishlist/add', {
        product_id : product.id
      })
      .then(res => {
        this.getWishlist()
        this.setState({ 
          toast : {
            show : true,
            message : 'تم اضافة المنتج لقائمة المفضلة',
            position : 'bottom',
            color : 'success'
          }
        });
        console.log('res', res);
      })
      .catch(err => {
        console.log('err', err);
        alert(err);
      });
    }
  }

  handleSharing() {
    const { product } = this.state;
    const shareData = {
      'title': product.name,
      'url': 'https://enab.com.sa/p/' + product.id
    };
    
    Mixpanel.track('Sharing Button', {
      screen : 'Product Page'
    });

    SocialSharing.share(shareData).then(function() {
      Mixpanel.track('Product Sharing Success', {
        product_id : Number(product.id),
        product_name : product.name,
        screen : 'Product Page'
      });
    }).catch(function(error) {
      Mixpanel.track('Product Sharing Error', {
        product_id : Number(product.id),
        product_name : product.name,
        screen : 'Product Page'
      });
    });
  }

  handlePlus() {
    const { product, quantity } = this.state;

    this.setState({
      quantity : quantity + 1
    })
  }

  handleMinus() {
    const { product, quantity } = this.state;
    if (quantity > 1) {
      this.setState({
        quantity : quantity - 1
      })
    }
  }

  handleQuantityChange(e) {
    const { product } = this.state;

    if (product.quantity == 0) {
      e = 1;
      this.setState({ 
        toast : {
          show : true,
          message : 'للاسف هذا المنتج نفد من المخزون'
        }
      });
      
    } else if (product.quantity < e) {
      e = product.quantity;
      this.setState({ 
        toast : {
          show : true,
          message : ` للاسف لا يمكنك طلب اكثر من ${product.quantity} `
        }
      });
    }

    this.setState({
      quantity : e,
      popoverQuantityState : { showPopover: false, event: undefined }
    });
  }

  getProduct() {
    const { productData } = this.props;
    const product_id = productData['id'];
    // this.productService.getProductWithView(product_id)
    //   .then(data => {
    //     let { product, shop } = data;
    //     this.setState({ product: {
    //       ...product,
    //       shop
    //     }, productLoaded : true });

    //   })
    //   .catch(err => {
    //     console.log('err', err);
    //   });
  }

  getWishlist() {
    this.http.post('wishlist', {})
      .then(res => {
        if(!res.error){
          this.setState({
            loading : false,
            wishlist : res?.wishlist.map(item => item?.product.id) || []
          });
        }
      })
      .catch(err => {
        console.log('Error loading wishlist', err)
      })
  }

  /**
   * Handle auto scroll for categories.
   */
  categoryScroll(ref = this.categoryRef) {
    if (ref) {
      Helper.scrollTo(ref, 'left');
    }
  }


  /**
   * Handles the selected category align.
   */
  handleSubCategoryChange(e, value) {
    if (e != null) {
      e.preventDefault();  
    }
    this.setState({ subCategory: value }); 
  }

  // TODO: This needs to be revamped until design gets finalized
  /**
   * Handles scroll event.
   * @param {Event} e
   */

  // async handleVariationChange() {
  //   const { product } = this.state;
  //   const self = this;
  //   let productParams = {};
  //   $( "#option-choice-form" )
  //     .serializeArray()
  //     .forEach(param => {
  //       productParams[param.name] = param.value;
  //       // if (param.name != 'id' && param.name != 'quantity') {
  //       //   variation += '-' + param.value; 
  //       // }
  //     });
    
  //   this.http.post('products/variant_price', productParams)
  //   .then(res => {
  //     console.log('variant_price', res);
  //     if ("product_id" in res) {
  //       console.log('product variation loaded');
  //       $('.product-price-primary').html(res.price_string);
  //       // let price = Number(res.price.replace('ريال', '').replace(',', ''));
  //       let price = res.price;
  //       self.setState({
  //         product : { ...product, price, variation : res.variation },
  //         variation : res.variation,
  //         product : {...product, quantity : res.quantity}
  //       }, () => {
  //         console.log('variant has been updated')
  //       });
  //     }
  //   })
  //   .catch(err => {
  //     console.log('variant_price', err);
  //   })
    
  // }

  handleSelectedOptions(optionsSelected, optionsError) {
    this.setState({
      optionsSelected,
      optionsError,
    });
  }

  async handleAddToCart() {
    const { onDismiss } = this.props;
    const { product, quantity, variation, productAddedToCart, optionsError, optionsSelected } = this.state;
    const { price, discount, discount_type } = product
    const { discountedPrice, percentOff } = ProductsHelper.getDiscountedPrice({ price, discount, discount_type });

    Mixpanel.track('Add To Cart', {
      product_id : Number(product.id),
      product_name : product.name,
      screen : 'Product Page'
    }); 

    if (optionsError != null) {
      this.setState({ 
        toast : {
          show : true,
          message : optionsError
        }
      });

      return;
    }

    // let productCartData = {
    //   id: product.id,
    //   name: product.name,
    //   image: product.image,
    //   price: discountedPrice,
    //   quantity: quantity,
    //   // variant : productParams,
    //   variants : optionsSelected
    // };

    let productCartData = Helper.mapProductToCart(product, quantity, optionsSelected);
    
    
    // for(let i = 0; i < cart.items; i++) {
    //   if (cart.items[i]['id'] == product.id) {
    //     this.props.addToCart(productCartData, {});
    //     break;
    //   }
    // }

    this.setState({
      addToCartButtonLoading : true
    });

    setTimeout(() => {
      this.props.addToCart(productCartData, {});
      onDismiss();
      this.setState({ 
        // productAddedToCart: !productAddedToCart,
        addToCartButtonLoading : false
      });
    }, 500);
  }

  handleOptionChange(group, selectedItem, check) {
    const { options } = this.state;
    const { max, required } = group;

    let newOptions = options.map((option) => {
      let newItems = option.items;
      let disabled = option.disabled;
      // only filter the group options
      if (group.id == option.id) {
        let selected_size = 0;
        newItems = option.items.map((item) => {
          let selected = item.selected;
          if (selectedItem['id'] == item['id']) {
            selected = check;
          }

          if (selected == true) {
            selected_size++;
          }

          return {
            ...item,
            selected,
          }
        });

        if (selected_size == max) {
          disabled = true;
        } else {
          disabled = false;
        }
      }

      return {
        ...option,
        disabled,
        items : newItems
      }
    });

    this.setState({
      options : newOptions
    })

  }

  navToCart() {
    const { history } = this.props;
    const { product } = this.state;
    
    Mixpanel.track('Go To Cart Button', {
      product_id : Number(product.id),
      product_name : product.name,
      screen : 'Product Page'
    });

    history.push(Helper.getRoute('cart'));
  }

  /**
   * Render the component.
   */
  render() {
    const { t, onDismiss } = this.props;
    const {
      product,
      productAddedToCart,
      productLoaded,
      addToCartButtonLoading,
      toast,
      maxCartQuantity,
      quantity,
      options
    } = this.state;

    

    const selectors = [];
    for (let index = 0; index < maxCartQuantity; index++) {
      selectors.push(<IonSelectOption value={index+1}>{`${index + 1} الكمية`}</IonSelectOption>);
    }
    
    const ImageSlider = () => {
      const { wishlist, product } = this.state
      const { id, images } = product
      return (
        <Swiper pagination={true} modules={[Pagination]} className="mySwiper" style={{marginTop: 10}}>
          {/* <IonFab vertical="top" slot="fixed" style={{marginTop: 10}} onClick={() => this.handleFav({isFav: wishlist.includes(id)})}>
              {
                wishlist.includes(id) ? <IonIcon icon={heart} style={{color: 'red'}} className="action-btn"/> : <IonIcon icon={heartOutline} className="action-btn"/>
              }
          </IonFab>

          <IonFab vertical="top" slot="fixed" style={{marginTop: 40}} onClick={this.handleSharing}>
              <IonIcon icon={shareSocial} className="action-btn"/>
          </IonFab> */}
          {
            images && images.map((image, index) => <SwiperSlide key={index}><img src={cdnImage(image)} /></SwiperSlide>)
          }
        </Swiper>
      )
    }

    const RenderPriceSection = () => {
      const { unit_price, price, discount, discount_type } = this.state.product
      const { discountedPrice, percentOff } = ProductsHelper.getDiscountedPrice({price, discount, discount_type})
      return (
        <>
          <IonRow>
            <IonCol size='5'>
              <div className='product-price'>
              { Helper.amountFormat({amount: discountedPrice, html: true, priceStyle: {fontSize: 24, fontWeight: '600'}, unitStyle: {fontSize: 14, fontWeight: 400}}) }
              </div>
            </IonCol>
            <IonCol>
              {/* <small className='label'>{" السعر شامل ضريبة القيمة المضافة"}</small> */}
            </IonCol>
          </IonRow>
          {
            discount > 0 && (
              <IonRow>
                <IonCol size='5'><span className='label'>{t('Price')}: </span>{ Helper.amountFormat({amount: price, html: true, strike: true, priceStyle: {fontSize: 14, fontWeight: 400}, unitClassName: 'unit'})}</IonCol>
                <IonCol>
                  <IonRow>
                    <IonText><small className='label'>{t('You saved')}: </small>{ Helper.amountFormat({amount: (price - discountedPrice), html: true, unitClassName: 'unit', priceStyle: {fontSize:20, fontWeight: '600'}}) }</IonText>
                    <IonBadge className='badge' style={{marginRight: 5, backgroundColor: '#FFE2E2', color: '#FF2F2F'}}>{t('Discount')}  {percentOff}%</IonBadge>
                  </IonRow>
                </IonCol>
              </IonRow>
            ) 
          }
        </>
      )
    }

    return (
      <>
        <Page
          withPage={false}
          backButton
          hasSearch
          noHeader
          style={{overflow: 'hidden'}}
          className={'product-view-page'}
        >
          <IonHeader mode="ios" className={'header ion-no-border'}>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton className="back-button" onClick={onDismiss}>
                  <IonIcon icon={chevronDownOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          
          <IonContent
            mode="md"
            scrollEvents
            onIonScrollStart={this.handleScrollEvent}
            onIonScroll={this.handleScrollEvent}
            ref={(ref) => (this.container = ref)}
          >
            {productLoaded == false ? (
              <div style={{ padding : '60px 0', 'textAlign' : 'center' }}>
                <Loading></Loading>
              </div>
            ) : (
              <div>
                {/* <IonText className='seller-name'>{product?.category?.name}</IonText><br/> */}
                
                {/* <IonBadge className='badge' style={{backgroundColor: '#FFAB49', color: 'white'}}><IonIcon style={{fontSize: 10}} icon={star} /> <strong style={{fontSize: 12}}>{product?.rating}</strong></IonBadge> */}
                <ImageSlider />
                <div className='product-title'>{product.name}</div>
                <IonGrid style={{marginTop: 20}}>            
                  <RenderPriceSection />
                  {/* <IonRow><IonCol><IonText color='success' style={{fontWeight: 400}}> متوفر </IonText></IonCol></IonRow> */}
                </IonGrid>

                {/* <IonGrid>
                  <IonRow>
                    <IonCol size='7'>
                      <button className='btn-return-policy'>
                        <IonIcon slot="start" icon={refreshCircleOutline} />
                        <IonText>عرض سياسة الإرجاع</IonText>
                        <IonIcon slot="end" icon={chevronBackOutline} />
                      </button>
                    </IonCol>
                    <IonCol size='6'>
                      <IonText><IonIcon style={{marginLeft: 2}} icon={storefrontOutline} /><small className='label'>البائع: </small><strong className='seller-name'>{product?.shop?.name}</strong></IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid> */}
                
                <IonGrid>
                  <IonRow>
                    <IonCol><div dangerouslySetInnerHTML={{__html: product.description}}></div></IonCol>
                  </IonRow>
                </IonGrid>


                <ProductOptions
                  variants={product.options}
                  t={t}
                  handleOptionsChanges={(selected_options, errors) => this.handleSelectedOptions(selected_options, errors)}
                ></ProductOptions>

                <IonFooter className="add-to-cart-button-container ion-no-border"
                  vertical="bottom" horizontal="end" slot="fixed" style={{margin: 0, padding: 0}}
                >
                  <div className='add-remove-content'>
                  <IonGrid style={{margin: 0, padding: 0}}>
                    <IonRow>
                      <IonCol size="6">
                        <IonButton onClick={() => this.handleAddToCart()} className="btn-add-to-cart" style={{height: 50}} disabled={addToCartButtonLoading} expand="full">
                          {t('Ajouter au panier')}
                        </IonButton>
                      </IonCol>
                      <IonCol size="6">
                        <div className="add-remove">
                          <IonGrid>
                            <IonRow>
                            <IonCol size="4">
                              <Button className={'btn-add-remove'} onClick={this.handleMinus} track="Cart Change - minus">
                              {/* <ion-icon name="remove-outline"></ion-icon> */}
                                <IonIcon icon={removeOutline}></IonIcon>
                              </Button>
                              </IonCol>

                              <IonCol size="4">
                                <div className="quantity">
                                  {quantity}
                                </div>
                              </IonCol>
                              
                              <IonCol size="4">
                                <Button className={'btn-add-remove'} onClick={this.handlePlus} track="Cart Change - plus">
                                  <IonIcon icon={addOutline}></IonIcon>
                                </Button>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </div>
                      </IonCol>
                      
                    </IonRow> 
                  </IonGrid>
                  </div>
                </IonFooter>
              </div>
            )}

            <IonToast
              position={toast.position ? toast['position'] : "top"}
              color={toast.color ? toast['color'] : "dark"}
              isOpen={toast.show}
              onDidDismiss={() => this.setState({toast : { show : false, message : '' }})}
              message={toast.message}
              duration={toast.time ? toast.time : 800}
            />
            </IonContent>
        </Page>
        <Modal
            title={t('تم اضافة المنتج لسلة')}
            onDismiss={this.handleProductAddedToCartDismiss}
            isOpen={productAddedToCart}
          >
            <div className="group" style={{ padding: '0 20px' }}>
              <form onSubmit={this.handleAddCustomProduct}>

                <div className="text-center">
                  <img src="/assets/images/tick.svg" style={{ width : '90px', height : '90px' }} />
                </div>

                <div className="form-control">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonButton 
                          expand="block" 
                          color="light" 
                          onClick={() => { this.setState({productAddedToCart : !productAddedToCart}) }}
                        >إكمال التسوق</IonButton>
                      </IonCol>
                      <IonCol>
                      <IonButton 
                        expand="block" 
                        color="primary"
                        onClick={() => { this.navToCart() }}
                      >إتمام الشراء</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </form>
            </div>
          </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ProductView));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cache, cart, auth, select }) {
  const { location } = createBrowserHistory();
  return {
    cart,
    cache,
    auth,
    history: select.history,
    category: select.category,
    location,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCategory: (data) => dispatch(SelectAction.setCategory(data)),
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
  };
}
