import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';

import {
  isPlatform,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSlides,
  IonSlide,
  IonSearchbar
} from '@ionic/react';

import './Home.scss';
import { Page, Modal } from 'components';
import ReactImageAppear from 'react-image-appear';
import classNames from 'classnames';
import {
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonLoading,
  IonList,
  IonLabel,
  IonToolbar,
} from '@ionic/react';


import List from './List/List';
import Banner from './Banner/Banner';
import ShopItem from 'components/Customs/ShopItem/ShopItem';

import Helper from 'Helper';
import { funnelOutline, addOutline, cloudUploadOutline, locationOutline } from 'ionicons/icons';
import ViewCart from 'components/ViewCart/ViewCart';
import BlockBuilder from 'components/BlockBuilder/BlockBuilder';
import { Radio, Button, Input } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import ProductItem from 'components/Customs/ProductItem/ProductItem';
import AppService from 'services/app.service';
import Http from 'libs/http';
import BlockHelper from 'libs/block-helper';
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { byStats } from '../Store/StoreData';

import SelectAction from 'store/actions/select';
import CartAction from 'store/actions/cart';
import CacheAction from 'store/actions/cache';

import { db } from 'services/firebase/firebase';
// import Searchbar from 'components/Searchbar/Searchbar';

import mixpanel from 'mixpanel-browser'

import AddressService from 'services/address.service';

smoothscroll.polyfill();

class Home extends Component {
  constructor(props) {
    super(props);

    this.http = new Http;

    this.blockHelper = new BlockHelper('home');
    this.addressService = new AddressService();

    this.state = {
      blocksHtml : '',
      categories: [],
      category: {},
      products: [],
      blocks : [],
      categoryType: 'MAIN',
      subCategory: 0,
      orderBy: '1',
      customImg: null,
      uploadedFile: null,
      productName: null,
      productDescription: null,

      shops : [],
      selected_group : null,

      refresher: false,
      refreshing: false,
      addingProduct: false,
      loading: true,
      uploading: false,
      sort: false,
      searchMode : false,
      banners : [],
      address : null,
      searchQuery : '',
      searchMode : false
    };

    this.container = null;
    this.categoryRef = null;
    this.uploadImageRef = null;
    this.refresher = null;
    this.subCategoryRef = null;
    this.subCategoryItemsRef = null;
    this.timeoutRef = null;

    this.service = new AppService();

    this.handleScrollEvent = this.handleScrollEvent.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    // this.handleSelectCetegory = this.handleSelectCetegory.bind(this);
    this.handleSelectingGroup = this.handleSelectingGroup.bind(this);

    // window.location.href = '/taymo';
  }

  get categories() {
    return [];
  }

  get shownShops() {
    const { shops, searchQuery, selected_group } = this.state;

    return shops.filter((shop) => {

      if (searchQuery.length != 0) {
        if (shop.name.includes(searchQuery)) {
          return true;
        } 
        return false;
      }

      if (selected_group == null || shop.groups.includes(selected_group)) {
        return true;
      }


      return false;
    });
  }

  /**
   * On init
   */
  async componentDidMount() {
    
    this.timeoutRef = setTimeout(() => {});

    const { cache, auth } = this.props;

    const { address } = cache;

    // this.loadCatalog();

    // this.loadShops();

    this.loadLocations();

    // this.loadProducts();

    this.loadShop();

    // console.log('props', cache);
  }

  /**
   * On unload
   */
  async componentWillUnmount() {
    window.removeEventListener('beforeunload', () => {});
  }

  /**
   * On state update.
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(pp, ps) {
    
  }



  reorderGroups(groups) {
    const { setCache, cache } = this.props;

    const categories = [];
    for(let i=0; i < groups.length; i++) {
      const group = groups[i];
      if(group.parent_id == 0) {
        categories.push({
          ...group,
          childrens : []
        });
      }
    }

    for(let i=0; i < categories.length; i++) {
      
     categories[i]['childrens'] = groups.filter(item => {
      if (item.parent_id == categories[i].id) {
        return true;
      }
      return false;
     });
    }

    setCache({ categories });

    return categories;
  }

  async loadShop() {
    const { setCache, cache } = this.props;
    const { catalog } = cache;
    const { content, loading } = this.state;
    
    this.setState({
      loading : true
    });

    if (catalog?.created_at != null) {
      const { categories, banners, products, blocks } = catalog.data;
      this.setState({ 
        banners,
        categories : this.reorderGroups(categories),
        products,
        blocks,
        loading : false,
      });
    }

    // let { filters } = cache;
    this.http.post('catalog/taymo', {})
    .then((data) => { 
      const { categories, banners, products, blocks } = data;
      
      this.setState({ 
        banners,
        categories : this.reorderGroups(categories),
        products,
        blocks,
        loading : false,
      });

      setCache({
        catalog : {
          data,
          created_at : new Date().getTime()
        }
      })

      // setTimeout(() => {
      //   this.setState({ 
      //     loading : false
      //   });
      // }, 200);
    })
    .catch(err => {
      this.setState({ loading : false, error : true });
      console.error('res', err);
    })
  }

  async getBlocks() {
    const { setCache } = this.props;

    this.setState({
      loading : true
    });

  }

  /**
   * Handle auto scroll for categories.
   */
  categoryScroll(ref = this.categoryRef) {
    if (ref) {
      Helper.scrollTo(ref, 'left');
    }
  }

  /**
   * Handles scroll event.
   * @param {Event} e
   */
  handleScrollEvent(e) {
    const { refresher } = this.state;
    const top = e.detail.scrollTop;

    // if (isPlatform('ios')) {
    if (this.subCategoryRef) {
      if (top > 0) {
        this.subCategoryRef.style.position = 'fixed';
      } else {
        this.subCategoryRef.style.position = 'absolute';
      }
    }

    if (top < 0 && Math.abs(top) >= 120 && !refresher) {
      this.handleRefresh();
      this.setState({ refresher: true });
    }
    // }
  }

  loadShops() {

    this.http.pgraph({
      "collection" : "Shop",
      "method" : "find",
      "args" : {
        "limit" : 12	
      }
    })
    .then(shops => {
      
      this.setState({
        shops
      });

    })
    .catch(err => {
      console.log('err', err);
    })

  }

  /**
   * Handles on refresh event.
   */
  handleRefresh() {
    if (!isPlatform('ios')) {
      // this.subCategoryRef.style.top = '0px';
    }

    this.setState({ refreshing: true });
  }

  handleSelectingGroup(group) {
    console.log('selected group', group);
    this.setState({
      selected_group : group.id
    })
  }

  handleSelectCategory(category) {
    const { history, setCategory } = this.props;
    
    mixpanel.track('Home Category Button', {
      name : category.name,
      id : category.id,
    })
    
    history.push('cat/' + category.id, {
      category : category,
    });
  }

  handleSelectShop(shop) {
    const { history } = this.props;
    history.push(Helper.getRoute('shop'), {
      shop : shop
    });
  }

  loadLocations() {
      
    const { auth, cache, setCache } = this.props;
    const user = auth.user;
    
    if (user.id) {
      const { address } = cache;
      console.log('load address', address);
      this.setState({
        address
      });

      this.addressService.getUserAddresses()
        .then(({error, addresses}) => {
          if(error) return null
          this.setState({
            address : address ? addresses.filter(addrs => addrs.id === address.id)[0] : addresses[0],
            loading : false 
          })
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }

  handleChangeLocation() {
    const { history } = this.props;
    history.push(Helper.getRoute('checkout/select-address'));
  }

  handleCreateDeliveryLocation() {
    const { history } = this.props;
    history.push(Helper.getRoute('checkout/add-location'), {
      returnTo : 'home'
    });
  }

  handleSearching() {
    const { searchQuery } = this.state;

  }

  loadProducts() {
    const { setCache, cache } = this.props;
    const { content, loading, searchFilter } = this.state;
    this.setState({
      loading : true
    });

    let { filters } = cache;
    this.http.post('catalog/products', searchFilter)
    .then(data => { 
      const { products } = data;

      this.setState({ 
        products,
        loading : false,
      });
    })
    .catch(err => {
      this.setState({ loading : false, error : true });
      console.error('res', err);
    }) 
  }

  handleSelectProduct(product) {
    window['blockLink']('product', product['id'], JSON.stringify(product));
  }

  /**
   * Render the component.
   */
  render() {
    const { t } = this.props;

    const {
      blocksHtml,
      category,
      subCategory,
      sort,
      orderBy,
      loading,
      addingProduct,
      customImg,
      uploading,
      categories,
      products,
      blocks,
      banners,
      shops,
      address,
      selected_group,
      searchQuery,
      searchMode,
    } = this.state;

     /**
     * Slider.
     */
    const sliderBanners = [
      {
        href: '#',
        imageUrl: '/assets/images/banners/hero-1.png',
      },
      {
        href: '#',
        imageUrl: '/assets/images/banners/hero-2.png',
      },
      {
        href: '#',
        imageUrl: '/assets/images/banners/hero-3.png',
      },
      {
        href: '#',
        imageUrl: '/assets/images/banners/hero-4.png',
      }
    ];

    // if (!categories) {
    //   return <Redirect to={Helper.getRoute('store')} />;
    // }


    const MainBannersLoading = () => (
      <IonList className="product-list loading">
        <IonItem lines="none" className="card-loading" style={{borderRadius : 5}}></IonItem>
      </IonList>
    );

    /**
     * Loading animation
     */
    const ProductLoading = () => (
      <IonList className="product-list loading">
        <IonGrid className="gs-products-grid">
          <IonRow>
            {[0, 1, 2, 3, 4, 5, 6].map((e) => (
              <IonCol key={String(e)} size="6">
                <IonItem lines="none" className="card-loading" style={{borderRadius : 5}}></IonItem>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonList>
    );

    const AddLocationComponent = (props) => {
      
      return (
        <div className='text-center' style={{margin : '0 -10px', 'paddingTop' : '50px'}}>
          <div >{t('Add your location first.')}</div>
          <button className="btn btn-xs" style={{ fontSize:"12px", padding: "4px 20px", "height" : "unset", "marginTop" : "10px" }}
            onClick={() => {
            this.handleCreateDeliveryLocation()
          }}>{t('Add location')}</button>
        </div>
      )
    }

    return (
      <>
        <Page
          // hasSearch
          title={t('Taymo')}
          className="home-page"
          withPage={false}
        >
          
          {/* <IonToolbar >
            <div className='text-center' onClick={() => {
              this.handleCreateDeliveryLocation()
            }}>
              <IonIcon name={locationOutline}></IonIcon>
              address
              {address?.label}
            </div>
          </IonToolbar> */}
          <IonContent>

              <>
                
                {loading == true && (
                  <>
                    <MainBannersLoading></MainBannersLoading>
                    <ProductLoading></ProductLoading>
                  </>
                )}

                <div className='ion-padding'>
                    {/* <Banner slide banners={banners} /> */}
                    {/* <h3>{t('Shops')}</h3> */}
                    <div className='shop-banners-list'>
                      {categories.map(cat => (
                        <div className='shop-banner' onClick={() => {
                            this.handleSelectCategory(cat)
                        }}>
                          <div className='cat-image'>
                            <img src={cat.banner} style={{borderRadius : '3px'}} />
                          </div>
                          {/* <div className='cat-title'>
                            {cat.name}
                          </div> */}
                          
                        </div>
                      ))}
                    </div>
                </div>
              </>

          </IonContent>
        </Page>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Home));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cache, auth, select }) {
  const { location } = createBrowserHistory();
  return {
    cache,
    auth,
    history: select.history,
    category: select.category,
    location,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCache: (data) => dispatch(CacheAction.setCache(data)),
    setCategory: (data) => dispatch(SelectAction.setCategory(data)),
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
  };
}
