import React, { Component } from 'react';
import { connect } from 'react-redux';

import './AddLocation.scss';
import { Page, Modal } from 'components';
import { withNamespaces } from 'react-i18next';
import { Input, Button } from 'components/Form';
import { IonIcon,
  IonModal,
  IonContent,
  IonHeader,
  IonButtons,
  IonButton,
  IonToolbar,
  IonFooter,
 } from '@ionic/react';


import { aim } from 'components/Icons';
import { locationSharp } from 'ionicons/icons';
import { GoogleMap, Autocomplete, withScriptjs, LoadScript } from '@react-google-maps/api';
import Helper from 'Helper';
import Http from 'libs/http';

import CacheAction from 'store/actions/cache';

import { Geolocation } from '@capacitor/geolocation';

import mixpanel from 'mixpanel-browser';

import * as _ from 'lodash';

import { 
  chevronDownOutline
 } from 'ionicons/icons';

const libraries = ['places', 'geocoder'];

class AddLocation extends Component {
  constructor(props) {
    super(props);

    const { auth } = this.props;
    const { user } = auth;

    this.http = new Http;

    this.map = null;

    this.defaultGeolocation = {
      lat : 34.005630,
      lng : -6.529304
    };

    this.state = {
      // 33.993277, 
      location: this.defaultGeolocation,
      locationForm : {
        name : '',
        street : '',
        building : '',
        // address : '',
        // city : 'riyadh',
        lat : 0,
        lng : 0,
        phone : user.phone,
        user_id : user.id,
        // postal_code : '123456',
        // country : 'المملكة العربية السعودية',
      },
      // address_details : {
      //   street : '',
        
      // },
      long_address : '',
      enterLabel: false,
      label: null,
      loaded: false,
      zoom: 16,
      loading : false,
      locationInfoModelOpen : false
    };


    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSelectPoint = this.handleSelectPoint.bind(this);
    this.getGeolocation = this.getGeolocation.bind(this);
  }

  async componentDidMount() {

    this.getGeolocation();

    this.setState({ loaded: true });

  }

  async handleSelectPoint() {
    const { locationForm } = this.state;
    let { center } = this.map.state.map;
    
    let lat = center.lat();
    let lng = center.lng();

    if (lat == undefined) {
      lat = this.defaultGeolocation['lat'];
      lng = this.defaultGeolocation['lng'];
    }

    
    let data = {
      address : '',
      city : 'Rabat'
    };
    // let data = await this.getReverseGeocodingData(lat, lng)
    //   .then(data => {
    //     console.log('data', data);
    //     return data;
    //   })
    //   .catch(err => {
    //     // alert(err);
    //     console.log('add-location', err);
    //     return {
    //       address : 'حي اليرموك',
    //       city : 'الرياض'
    //     };
    //   });

    this.setState({
      locationForm : {
        ...locationForm,
        address : data['address'],
        city : data['city'],
        lat,
        lng
      }
    })

    this.setState({ locationInfoModelOpen: true })

  }

  getReverseGeocodingData(lat, lng) {
    return new Promise((resolve, reject) => {
      const google = window.google;
      var latlng = new google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'latLng': latlng },  (results, status) => {
          if (status !== google.maps.GeocoderStatus.OK) {
            return reject(status);
          }

          // This is checking to see if the Geoeode Status is OK before proceeding
          if (status == google.maps.GeocoderStatus.OK) {
            let city = '';
            for (var i=0; i<results[0].address_components.length; i++) {
              for (var b=0;b<results[0].address_components[i].types.length;b++) {
  
              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                if (results[0].address_components[i].types[b] == "locality") {
                  //this is the object you are looking for
                  city = results[0].address_components[i]['short_name'];
                  break;
                }
              }
            }

            var address = (results[0].formatted_address);

            return resolve({
              address,
              city
            });
          }
      });
    });
  }

  /**
   * Handles Select Position.
   */
  async getGeolocation() {
    const { location } = this.state;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        this.setState({ location: { lat: latitude, lng: longitude } });

      }, (error) => {
        this.showError(error);
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }

  }

  showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
    }
  }

  /**
   * Handle confirm button.
   */
  handleConfirm() {
    const { locationForm } = this.state;
    const { setCache, t } = this.props;

    const returnTo = _.get(this.props, 'history.location.state.returnTo', null);

    this.setState({ enterLabel: false });
    if (this.map) {

      this.http.post('customer/address/create', locationForm)
        .then(res => {
          const { address, branch, available } = res;

          if (available != true) {
            alert(t('Your location is out of service'));
            this.props.history.push(Helper.getRoute('home'));
            return;
          }

          setCache({
            address,
            branch
          });

          mixpanel.track('Address added');

          if (returnTo) {
            this.props.history.push(Helper.getRoute(returnTo));
          } else {
            this.props.history.push(Helper.getRoute('cart'));
          }

          
        })
        .catch(err => {
          mixpanel.track('Adding Address error');
          window.alert('وقع خطئ ما بالرجاء تاكد من المعلومات');
        });

    }
  }

  handleLocationInfoModalDismiss() {
    this.setState({
      locationInfoModelOpen : false
    })
  }

  // LocationInfoModal = () => {
  //   return (
  //     <Page
  //       withPage={false}
  //       backButton
  //       hasSearch
  //       noHeader
  //       className="product-view-page"
  //     >
  //       <IonHeader mode="ios" className={'header ion-no-border'}>
  //         <IonToolbar>
  //           <IonButtons slot="start">
  //             <IonButton className="back-button" onClick={this.handleLocationInfoModalDismiss}>
  //               <IonIcon icon={chevronDownOutline} />
  //             </IonButton>
  //           </IonButtons>
  //         </IonToolbar>
  //       </IonHeader>
        
  //       <IonContent class="fixed-sub-category not-pulling">
  //         <div className="enter-label">
  //           <div className="form-control">
  //             <Input
  //               name="label"
  //               onChange={(label) => this.setState({ label })}
  //               placeholder={t('Label')}
  //             />
  //           </div>
  //           <div className="form-control">
  //             <Input
  //               name="label"
  //               onChange={(label) => this.setState({ label })}
  //               placeholder={t('رقم الهاتف')}
  //             />
  //           </div>
  //           <div className="form-control">
  //             <Button onClick={this.handleConfirm} large full>
  //               {t('Save')}
  //             </Button>
  //           </div>
  //         </div>
  //       </IonContent>
  //     </Page>
  //   )
  // }

  /**
   * Render the component.
   */
  render() {
    const { 
      zoom,
      location, 
      locationInfoModelOpen,
      loaded,
      locationForm
    } = this.state;

    const { t } = this.props;

    /**
     * Google Map Container.
     */
    const MapContainer = loaded && (
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        libraries={libraries}
      >
        <GoogleMap
          ref={(ref) => (this.map = ref)}
          mapContainerClassName="map"
          center={location}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
          }}
        >
          {/* <Autocomplete className="search-input">
            <Input placeholder={t('Search for your place')} />
          </Autocomplete> */}
        </GoogleMap>
      </LoadScript>
    );

    const LocationInfoModal = (
      <div className="location-info-modal">
        <div class="fixed-sub-category not-pulling" >
          <div className="enter-label">
            <div className="form-control long_address-container">
              <div className="long_address-header">
                {t('Address')}
              </div>
              <div className="long_address-content d-none">
                {this.state.locationForm.address}
              </div>
            </div>
            {/* <div className="form-control">
              <Input
                name="name"
                onChange={(name) => this.setState({ locationForm : {...locationForm, name} })}
                placeholder={t('Choose a name for this address')}
              />
            </div> */}
            <div className="form-control">
              <Input
                name="street"
                onChange={(street) => this.setState({ locationForm : {...locationForm, street} })}
                placeholder={t('Street')}
              />
            </div>
            <div className="form-control">
              <Input
                name="building"
                onChange={(building) => this.setState({ locationForm : {...locationForm, building} })}
                placeholder={t('Building')}
              />
            </div>

            <div className="form-control" style={{display : 'none'}}>
              <Input
                name="phone"
                onChange={(phone) => this.setState({ locationForm : {...locationForm, phone} })}
                placeholder={t('Phone number')}
                value={this.state.locationForm.phone}
              />
            </div>
            <div className='form-control'>
              <Button onClick={this.handleConfirm} large full>
                {t('Save')}
              </Button>
            </div>
          </div>
            
          </div>
      </div>
    );

    return (
      <>
        <Page className="location-page has-no-tabs" backButton title={t('Select locations')}>
          <div className="wrapper">
            {MapContainer}
            <IonIcon className="location-mark" icon={locationSharp} />
            <div className="actions">
              <div className="info">
                <IonIcon
                  onClick={this.getGeolocation}
                  className="auto-select"
                  icon={aim}
                />
              </div>
              <div className="button">
                <Button onClick={this.handleSelectPoint} full large>
                  {t('Confirm')}
                </Button>
              </div>
            </div>
          </div>
        </Page>
        <Modal
          title={t('Location info')}
          onDismiss={() => this.handleLocationInfoModalDismiss()}
          isOpen={locationInfoModelOpen}
        >
          {LocationInfoModal}
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(AddLocation));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, select }) {
  return {
    auth,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
