import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';
import {
  isPlatform,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  IonText,
  IonItem,
  IonSlides,
  IonSlide,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonChip,
  IonFooter
} from '@ionic/react';

import './Category.scss';
import { Page, Modal } from 'components';
import ReactImageAppear from 'react-image-appear';

import {
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonList,
  IonLabel,
  IonToolbar,
} from '@ionic/react';
import Helper from 'Helper';
import ProductsHelper from './ProductsHelper';
import { funnelOutline, addOutline, cloudUploadOutline, search, filter, cart, car } from 'ionicons/icons';
import { search as searchIcon, chevronDownOutline, closeOutline } from 'ionicons/icons';
import ViewCart from 'components/ViewCart/ViewCart';
import { Radio, Button, Input } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import ProductItem from 'components/Customs/ProductItem/ProductItem';
import AppService from 'services/app.service';
import Http from 'libs/http';
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import { byStats } from '../Store/StoreData';
import SelectAction from 'store/actions/select';
import CartAction from 'store/actions/cart';
import CacheAction from 'store/actions/cache';

import { cdnImage, amountFormat } from 'libs/pipes';
import classNames from 'classnames';

import _ from 'lodash';

import Searchbar from 'components/Searchbar/Searchbar';

import mixpanel from 'mixpanel-browser'

smoothscroll.polyfill();

class Category extends Component {
  constructor(props) {
    super(props);
    
    this.http = new Http;
    let searchMode = false;
    let filter = {category_id: 0, page: 0};

    // const shopName = _.get(this.props, 'location.pathname', '/').split('/').pop();

    

    const { category  } = _.get(this.props, 'history.location.state', {
      category : null
    });

    let category_id = _.get(this.props, 'location.pathname', '/').split('/').pop();
    console.log('category_id', category_id);
    // if (category != null) {
    //   category_id 
    // }

    const shopName = category?.name || 'Taymo';
    
    this.state = {
      content : [],
      categories: [],
      subcategories : [],
      category: category,
      products: [],
      categoryType: 'MAIN',
      subCategory: 0,
      orderBy: '1',
      shopName : shopName,
      customImg: null,
      uploadedFile: null,
      all_products : [],


      searchResults : [],
      searchMode : false,
      searching : false,
      searchQuery : '',

      filters : [],
      selectedFilter : null,
      filterModalOpen: false,

      productName: null,
      productDescription: null,

      error : false,

      searchFilter : {
        ...filter,
        search : '',
      },

      category_id : category_id,
      subcategory_id : null,
      term : '',

      loadingMore : false,

      refresher: false,
      refreshing: false,
      addingProduct: false,
      loading: true,
      uploading: false,

      isStillMoreProducts : true,

      productsLoading : false,

      sort: false,
    };

    this.container = null;
    this.contentContainer = null;
    this.categoryRef = null;
    this.uploadImageRef = null;
    this.refresher = null;
    this.subCategoryRef = null;
    this.subCategoryItemsRef = null;
    this.timeoutRef = null;
    this.intervalRef = null;

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleSubcategoryChange = this.handleSubcategoryChange.bind(this);
    this.handleScrollEvent = this.handleScrollEvent.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleCustomProductDismiss = this.handleCustomProductDismiss.bind(this);
    this.handleAddCustomProduct = this.handleAddCustomProduct.bind(this);
    this.handleCancelProduct = this.handleCancelProduct.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.navToCart = this.navToCart.bind(this);

    this.handleFilterModalDismiss = this.handleFilterModalDismiss.bind(this);

    this.service = new AppService();
  }

  get get_products() { 
    const { all_products, category_id, subcategory_id } = this.state;

    if (subcategory_id != null) {
      return all_products.filter(product => {
        if (product.subcategory_id == subcategory_id) {
          return true;
        }

        return false;
      });
    }

    if (category_id != null) {
      return all_products.filter(product => {
        if (product.category_id == category_id) {
          return true;
        }

        return false;
      });
    }

    return [];
  }


  async loadShop() {
    const { setCache, cache, auth } = this.props;
    const { user, branch } = cache;
    const { shopName } = this.state;

    try {

      const { products, categories, shop } = await this.http.post('catalog', {channel : 'menu'}, {
        headers : {
          'X-Shopname' : shopName
        }
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log('err', err.response);
        console.error('Error', err.message || err);
      });

      console.log('shop data loaded', products, categories, shop);

      const primaryCategories = categories.filter(item => {
        if (item.parent_id != 0) {
          return false;
        }
        return true;
      });


      this.setState({
        all_products : products,
        all_categories : categories,
        categories : primaryCategories,
        shop,
        loading : false
      }, () => {

        this.handleCategoryChange(primaryCategories[0]);
      })

      let primaryBranch = {
        id : 1
      }
    
      // const newProductsList = Helper.productMap(products, {inventory_id : 1});

      // if ('id' in user == false) {
      //   setCache({
      //     user : auth['user']
      //   });
      // }

      // if ('id' in branch == false) {
      //   setCache({
      //     branch : primaryBranch
      //   });
      // }
      
      setCache({
        shop,
        branches : [],
        // products : newProductsList,
        categories : {
          data : categories
        },
        branch : primaryBranch,
        // user
      });

    }
    catch(err) {
      console.log('Error', err.message)
    }
  }

  // loadProducts

  loadData() {
    const { category_id } = this.state;

    this.http.post('catalog/products', {
      category_id
    })
    .then(data => { 
      const { products, categories } = data;

      this.setState({ 
        all_products : products,
        subcategories : categories,
        loading : false,
      }, () => {
        if (categories.length != 0) {
          this.handleSubcategoryChange(categories[0]);
        }
      });
    })
    .catch(err => {
      this.setState({ loading : false, error : true });
      console.error('res', err);
    }) 

  }


  handleInit() {
    const { cache } = this.props;
    let groups = cache.categories || null;
    const categories = groups.filter(item => {
      if (item.parent_id == 0) {
        return true;
      }

      return false;
    })

    let selectedCategory = categories ? categories[0] : null;

    this.setState({ 
      categories,
      category: selectedCategory
    });
  }

  getSubcategories(category_id) {
    const { cache } = this.props;
    let groups = cache.categories || null;
    return groups.filter(item => {
      
      if (category_id == item.parent_id) {
        return true;
      }

      return false;
    });
  }

  get categories() {
    const { categories, category } = this.state;
    return categories.find((c) => c.id === category.id);
  }

  /**
   * On init
   */
  async componentDidMount() {
    const { cache } = this.props;

    this.loadData();

    // const { category, subcategory, group_id, term } = _.get(this.props, 'history.location.state', {
    //   term : null,
    //   group_id : null,
    //   category : null
    // });

    // let categories = cache.categories || [];
    // let selectedCategory = this.state.category != null ? this.state.category : categories[0];
    
    // this.setState({ 
    //   categories,
    // });

    // if (this.props.history != null) {
    //   let state = this.props.history.location.state;
    //   if ('mode' in state) {
    //     this.setState({
    //       searchMode : true
    //     });
    //   }
    // }

    // this.handleCategoryChange(selectedCategory);
   
  }

  /**
   * On unload
   */
  async componentWillUnmount() {
    window.removeEventListener('beforeunload', () => {});
    await this.handleCancelProduct();
    clearInterval(this.intervalRef);
  }

  /**
   * On state update.
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(pp, ps) {
    const { category, subCategory, orderBy } = this.state;
    // if (ps.category !== category || ps.subCategory !== subCategory || ps.orderBy !== orderBy) {
    //   if (category['sub'].length != 0 && subCategory == 0) {
    //     this.setState({ subCategory: category['sub'][0]['id'] });
    //   }
    //   this.getProducts();
    // }

    // this.setState({ category: category.id });

    // console.log('mqw', category);
  }


  /**
   * Handle auto scroll for categories.
   */
  categoryScroll(ref = this.categoryRef) {
    if (ref) {
      Helper.scrollTo(ref, 'left');
    }
  }

  /**
   * Handles the selected category align.
   */
  handleCategoryChange(category) {
    const { cache } = this.props;
    const { searchFilter, all_categories, all_products } = this.state;
    

    let products = [];
    let subcategories = all_categories.filter(item => {
      if (item.parent_id == category.id) {
        return true;
      }

      return false;
    });

    console.log('subcategories', all_categories);

    if (category == undefined) {
      category = all_categories[0];
    }

    let subcategory = subcategories ? subcategories[0] : {};

    if (subcategories.length == 0) {
      products = all_products.filter(product => {
        if (product.category_id == category['id']) {
          return true;
        }
        return false;
      });
    }

    if (subcategories.length != 0) {
      products = all_products.filter(product => {
        if (product.subcategory_id == subcategory['id']) {
          return true;
        }

        return false;
      });
    } 

    this.setState({
      subcategories,
      products,
      searchFilter : {
        ...searchFilter,
        category_id : category.id,
      },
      category_id : category.id,
      subcategory_id : subcategory?.id
    }, () => {
      // this.loadProducts();
    })

    // this.slideRef.slideNext();
  }

  /**
   * Handles the selected category align.
   */
  handleSubcategoryChange(group) {
    const { searchFilter } = this.state;

    // this.setState({ subCategory: value }); 

    this.setState({
      subCategory: group,
      subcategory_id :  group.id,
      searchFilter : {
        ...searchFilter,
        subcategory_id : group.id
      }
    }, () => {
      // this.loadProducts();
    })
  }


  loadProducts() {
    const { setCache, cache } = this.props;
    const { content, loading, searchFilter } = this.state;
    this.setState({
      loading : true
    });

    let { filters } = cache;
    this.http.post('catalog/products', searchFilter)
    .then(data => { 
      const { products } = data;

      this.setState({ 
        products,
        loading : false,
      });
    })
    .catch(err => {
      this.setState({ loading : false, error : true });
      console.error('res', err);
    }) 
  }

  loadContent() {
    const { content, searchFilter, products } = this.state;

    this.setState({
      loadingMore : true,
    });

    this.http.post('products/search', searchFilter)
    .then(data => {
      let isStillMoreProducts = true;

      // if (data.products.length == 0) {
      //   isStillMoreProducts = false;
      // }

      if (data['products'].length < 19) {
        isStillMoreProducts = false;
      }

      this.setState({ 
        products : [
          ...products,
          ...data.products
        ],
        // filters : res.filters,
        loadingMore : false,
        // content : [...content, {html : res.html, page : res.params.page}],
        isStillMoreProducts
      });
    })
    .catch(err => {
      this.setState({ loadingMore : false, error : true });
      console.log('res', err);
    })
  }

  

  /**
   * Handles scroll event.
   * @param {Event} e
   */
  handleScrollEvent(e) {

  }

  /**
   * Handles on refresh event.
   */
  handleRefresh() {
    if (!isPlatform('ios')) {
      // this.subCategoryRef.style.top = '0px';
    }

    this.setState({ refreshing: true });
  }

  async handleCustomProductDismiss() {
    const { addingProduct } = this.state;
    this.setState({ addingProduct: !addingProduct });
    await this.handleCancelProduct();
  }

  /**
   * Handles canceling custom product.
   */
  async handleCancelProduct() {
    const { uploadedFile } = this.state;
    if (uploadedFile) {
      this.setState({ customImg: null, uploadedFile: null });
      await uploadedFile.delete();
    }
  }

  /**
   * Handles upload file.
   */
  async handleUploadFile(e) {
    const file = e.target.files[0];
    if (file) {
      this.setState({ uploading: true });
      try {
        const { ref } = await this.service.uploadFile(file);
        const customImg = await ref.getDownloadURL();
        this.setState({ customImg, uploading: false, uploadedFile: ref });
      } catch (e) {
        console.error('Failed to upload the image.');
      }
    }
  }

  /**
   * Handles adding new custom product.
   * @param {Object} e
   */
  handleAddCustomProduct(e) {
    e.preventDefault();
    const { customImg, productName, productDescription, addingProduct } = this.state;
    const { addToCart, auth } = this.props;

    const product = {
      id: Helper.uid(16),
      name: { en: productName, ar: productName },
      price: 0,
      description: productDescription,
      img: customImg,
      type: 'CUSTOM',
    };

    addToCart(product, auth);

    this.setState({ addingProduct: !addingProduct, customImg: null, uploadedFile: null });
  }

  handleFilterClick(filter) {
    
    let options = filter.options.map(option => {
      console.log('item', option);
      return {
        label : option[filter.adapter.label],
        value : option[filter.adapter.value]
      };
    });
    
    this.setState({ filterModalOpen : true, selectedFilter : {...filter, options}});
  }

  handleFiltering(option) {
    const {selectedFilter, searchFilter} = this.state;
    
    let newFilter = {};
    newFilter[selectedFilter.name] = option.value;
    this.handleFilterModalDismiss();
    this.setState({
      searchFilter : {...searchFilter, ...newFilter, page : 0},
      isStillMoreProducts : true
    }, () => {
      // this.loadInitContent()
      this.loadProducts();
    })
    
  }

  handleFilterModalDismiss() {
    this.setState({
      filterModalOpen : false
    });
  }

  handleSelectSearchTerm(term) {
    console.log('term', term);
    const {searchFilter} = this.state;
    this.setState({
      searchMode : false,
      searchFilter : {
        query : term['query']
      },
    }, () => {
      // this.loadInitContent();
      this.loadProducts();
    });
  }

  handleSearching(value) {
    if (value) {
      // setLoading(true);
      
      this.setState({
        searching : true,
        searchQuery : value
      });

      this.service
        .search(value)
        .then((results) => {
          const { products } = results;
          this.setState({
            searchResults : products,
          });

          // setLoading(false);
        })
        .catch((err) => {
          console.log('err', err);
        }); 
    }
  }

  handleSelectProduct(product) {
    window['blockLink']('product', product['id'], JSON.stringify(product));
  }

  navToCart() {
    const { history, auth } = this.props;

    console.log('auth', auth);

    if (auth['user']['isGuest'] == true) {
      
      history.push(Helper.getRoute('guest'), {
        returnTo : Helper.getRoute('cart')
      });

      return;
    }

    history.push(Helper.getRoute('cart'));
  }

  /**
   * Render the component.
   */
  render() {
    const { t, cart } = this.props;
    
    const {
      sort,
      orderBy,
      loading,
      addingProduct,
      customImg,
      uploading,
      shop,
      categories,
      category,
      products,
      isStillMoreProducts,
      shopName,
      content,
      filters,
      filterModalOpen,
      selectedFilter,
      searchMode,
      searching,
      searchQuery,
      subcategories,
      category_id,
      subcategory_id
    } = this.state;

    const Categories = (
      <IonToolbar className="topbar-categories categories">
        <IonSegment
          mode="md"
          ref={(ref) => {
            this.categoryRef = ref;
            this.categoryScroll();
          }}
          scrollable="true"
          value={category_id}
        >
          {categories.map((c) => (
            <IonSegmentButton
              key={c.id}
              onClick={() => {
                this.handleCategoryChange(c);
                mixpanel.track('Category button - products', {
                  name : c.name,
                  group_id : c.id
                });
              }}
              value={c.id}
              className={classNames({'segment-button-checked' : category_id == c.id})}
            >
              <div className="topbar-category-image">
                <img src={c.image} ></img>
              </div>
              <div className="topbar-category-name">{c.name}</div>
            </IonSegmentButton>
          ))}
        </IonSegment>
      </IonToolbar>
    );

    const Subcategories = (
      <IonToolbar class="toolbar-subcategories">
        <IonSegment
          mode="md"
          ref={(ref) => {
            this.categoryRef = ref;
            this.categoryScroll();
          }}
          scrollable="true"
          value={subcategory_id}
        >
          {subcategories.map((c) => (
            <IonSegmentButton
              key={c.id}
              onClick={() => {
                this.handleSubcategoryChange(c);
                mixpanel.track('Subcategory button - products', {
                  name : c.name,
                  group_id : c.id
                });
              }}
              value={c.id}
              className={classNames({'segment-button-checked' : subcategory_id == c.id})}
            >
              <div>
                <img src={c.image} style={{'width' : '60px'}}></img>
              </div>
              <div style={{ 'paddingTop' : '1px', 'paddingBottom' : '10px' }}>{c.name}</div>
            </IonSegmentButton>
          ))}
        </IonSegment>
      </IonToolbar>
    );
    
    /**
     * Loading animation
     */
    const Loading = () => (
      <div style={{padding : '20px'}}>
        <IonList className="product-list loading">
          <IonGrid className="gs-products-grid">
            <IonRow>
              {[0, 1, 2, 3, 4, 5, 6].map((e) => (
                <IonCol key={String(e)} size="6">
                  <ProductItem loading />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonList>
      </div>
    );

    const FilterComponent = () => (
      <div className="">
          {filters.map((e) => (
            <IonChip key={String(e.name)} onClick={() => this.handleFilterClick(e)}>
              <IonLabel color="secondary">{e.label}</IonLabel>
            </IonChip>
          ))}
      </div>
    );

    const SubcategoriesComponent = () => (
      <div className="">
          {subcategories.map((e) => (
            <IonChip key={String(e.name)} onClick={() => this.handleFilterClick(e)}>
              <IonLabel color="secondary">{e.name}</IonLabel>
            </IonChip>
          ))}
      </div>
    );

    const SearchContent = () => {
      const { searching, searchResults } = this.state;
      return (
        <IonContent>
          <div className="search-container">
            { searching ? (
              <div >
                <div className="search-result">
                  <IonList className="product-list">
                    <IonGrid className="gs-products-grid">
                      <IonRow>
                        {searchResults.map((p) => (
                          <IonCol key={p.id} size="6">
                            <ProductItem 
                              product={p} 
                              onClick={() => { this.handleSelectProduct(p) }}
                            />
                          </IonCol>
                        ))}
                      </IonRow>
                    </IonGrid>
                  </IonList>

                  {/* <div className="search-result-item" onClick={() => { this.handleSelectSearchTerm({
                    id : 0,
                    query : searchQuery
                  }) }}>
                    <div style={{ fontWeight : 'bold' }}>عرض الكل</div>
                  </div> */}

                </div>
              </div>
            ) : (
              <div className="">
                <div style={{paddingTop : '130px', textAlign : 'center'}}>
                  <img src="assets/icon/search-page.png" style={{width:'150px'}} />
                  <div style={{paddingTop : '5px'}}>#اقطفها  بسهولة</div>
                </div>
              </div>
            )}
          </div>
        </IonContent>
      );
    }

    const searchingButton = {
      icon: searchIcon,
      onClick: () => {
        mixpanel.track('Search Button - Products')
        this.setState({
          searchMode : true
        })
      }
    }

    const ProductCard = (product) => {
      const {unit_price, discount, discount_type} = product
      const { discountedPrice, percentOff } = ProductsHelper.getDiscountedPrice({unit_price, discount, discount_type})

      return (
        <div className="product-card">
          <div className="product-img">
            <img src={cdnImage(product.image)} />
          </div>
          <div className="product-info">
            <div className="product-name">
              {product.name}
            </div>
            <>{Helper.amountFormat({amount: discountedPrice, priceStyle: {fontSize: 18}})}</>
            <div style={{display: 'block', height: 30}}>
              {
                discount > 0 && (
                  <IonRow style={{marginRight: 2}}>
                    <IonText>{ Helper.amountFormat({amount: product.unit_price, strike: true, unitClassName: 'unit', priceStyle: {fontSize: 14, fontWeight: '300'}}) }</IonText>
                    <IonBadge className='badge' style={{marginRight: 5, backgroundColor: '#FFE2E2', color: '#FF2F2F'}}>خصم  {percentOff}%</IonBadge>
                  </IonRow>
                )
              }
            </div>
          </div>
        </div>
      );
    }; 

    const NoResults = () => {
      return (
        <div style={{
          padding: '20px 0',
          textAlign: 'center',
          paddingTop: '40px'
        }}>
          {t('No products found for this search.')}
        </div>
      );
    }

    /**
     * List of products
     */
     const Products = (
      <IonList className="product-list">
        <IonGrid className="gs-products-grid">
          <IonRow>
            {products.map((p) => (
              <IonCol key={String(p.id)} size="6">
                <ProductItem product={p} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonList>
    );

    return (
      <>
        <Page
          withPage={false}
          backButton
          // headerButtons={[searchingButton]}
          hasCart={false}
          title={shopName}
          className="products-page"

          // headerChildren={ searchMode && (
          //   <Searchbar />
          // )}
        >
          {searchMode ? (
            <Searchbar />
          ) : (
            <>
              {/* {Categories} */}
          
              {Subcategories}
            </>
          )}
          
          <IonContent
            scrollEvents={true}
            onIonScrollStart={this.handleScrollEvent}
            onIonScroll={this.handleScrollEvent}
            ionScrollEnd={this.handleScrollEvent}
            className="fixed-sub-category not-pulling"
            ref={(ref) => (this.container = ref)}
          >

            {loading ? <Loading /> : (
              <div>
                { this.get_products.length == 0 ? <NoResults /> : (
                  <div
                    ref={(ref) => (this.contentContainer = ref)}
                    id="content-blocks"
                  >
                      <IonList className="product-list">
                        <IonGrid className="gs-products-grid">
                          <IonRow>
                            {this.get_products.map((item) => (
                              <IonCol key={item.id} size="6">
                                {/* <ProductCard  {...item} ></ProductCard> */}
                                <ProductItem 
                                  product={item} 
                                  onClick={() => { this.handleSelectProduct(item) }}
                                />
                              </IonCol>
                            ))}
                          </IonRow>
                        </IonGrid>
                      </IonList>
                  </div>
                )} 
              </div>
            )}

          <div 
            className={classNames('nav-to-cart', {'d-none' : cart.total == 0})}
          >
            <Button onClick={this.navToCart}
              track={['View Cart', {
                items : cart.total,
                amount : cart.amount
              }]}
            >
              {t('products.view-cart', {
                count : cart.total
              })}
              {Helper.amountFormat({amount: cart.amount})}
              {/* {t('Order')} {cart.total} for {Helper.amountFormat({amount: cart.amount})} */}
            </Button>
            
          </div>
        </IonContent>
          
        </Page>
        <Modal
          // title={selectedFilter.label}
          onDismiss={this.handleFilterModalDismiss}
          isOpen={filterModalOpen}
          className="filter-modal-container"
        >
          <div style={{padding : '20px'}}>
            {selectedFilter != null && selectedFilter.options.map((item) => (
              <div className="filter-list-item" key={item.value} onClick={() => {this.handleFiltering(item)}}>{item.label}</div>
            ))}
          </div>
        </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Category));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cache, auth, select, cart }) {
  const { location } = createBrowserHistory();
  return {
    cart,
    cache,
    auth,
    history: select.history,
    category: select.category,
    location,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCategory: (data) => dispatch(SelectAction.setCategory(data)),
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
